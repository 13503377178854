import {Button, Stack} from '@mui/material'
import {
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  useGridApiContext
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import DownloadIcon from '@mui/icons-material/Download'

const InstitutionalPaymentsToolBar = observer(
  ({setFilterButtonEl}: GridToolbarProps) => {
    const {selectedPaymetMonth} = useMainStore().institutionStore

    const apiRef = useGridApiContext()

    const downloadExcel = () => {
      const fileName = selectedPaymetMonth
        ? 'תשלומים למוסדות_' +
          Number(selectedPaymetMonth.getMonth() + 1) +
          '_' +
          selectedPaymetMonth.getFullYear()
        : 'תשלומים למוסדות'
      apiRef.current.exportDataAsCsv({utf8WithBom: true, fileName: fileName})
    }

    return (
      <>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton ref={setFilterButtonEl} />
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              sx={{ml: 1.5}}
              variant="outlined"
              color="inherit"
              startIcon={<DownloadIcon sx={{ml: 1, mr: -1}} />}
              onClick={downloadExcel}
            >
              {' '}
              הורדה{' '}
            </Button>
          </Stack>
        </Stack>
      </>
    )
  }
)
export default InstitutionalPaymentsToolBar
