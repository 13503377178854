import {
  Box,
  Card,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import {ReactComponent as AccountIcon} from 'src/images/ic_account.svg'
import {useMainStore} from '../../contexts/Main'
import {observer} from 'mobx-react-lite'
import {useEffect, useState} from 'react'
import {ReactComponent as Payment} from 'src/images/ic_payment.svg'
import Month from 'src/entities/Month'
import {defaultMonth} from 'src/utils/month'

const PaymentSummary = observer(() => {
  const {currentInstitution, relevantMonths} = useMainStore().institutionStore
  const {getStudentsDashboard, dashboardByMonth} = useMainStore().studentsStore

  const [month, setMonth] = useState<Month | undefined>(defaultMonth)

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted && currentInstitution) {
          getStudentsDashboard(currentInstitution.id)
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getStudentsDashboard, currentInstitution])

  const handleChangeMonth = (event: any) => {
    const selectedMonth = JSON.parse(event.target.value) as Month
    setMonth(selectedMonth)
  }

  const dashboard = month && dashboardByMonth?.[month?.year][month.id]

  return (
    <Card
      sx={{
        p: 2,
        m: 1.5,
        justifyContent: 'space-between',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Stack
        width="70%"
        spacing={2}
        sx={{
          alignItems: 'center',
          mb: 2
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            gap: 2
          }}
        >
          <Typography fontSize={14} fontWeight={500}>
            נתונים לחודש:
          </Typography>
          <Select
            sx={{
              width: '122px',
              height: '34px',
              minWidth: 'fit-content',
              fontSize: '14px',
              fontWeight: '700',
              backgroundColor:
                'var(--transparent-grey-12, rgba(145, 158, 171, 0.12))'
            }}
            value={month ? JSON.stringify(month) : ''}
            onChange={handleChangeMonth}
          >
            {relevantMonths?.map((m, i) => (
              <MenuItem
                sx={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: '700'
                }}
                value={JSON.stringify(m)}
                key={i}
              >
                {`${m.name} ${m.year}`}
              </MenuItem>
            ))}
          </Select>
          <AccountIcon />
          <Box sx={{flexGrow: 1}} />
        </Stack>

        <Stack
          spacing={4}
          width={'100%'}
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'center',
            gap: 1,
            flexWrap: 'wrap'
          }}
        >
          <StudentTypeSummery
            type={'בחורים'}
            sum={dashboard?.payYeshivaCount?.toString() || ''}
            datotRate={dashboard?.yeshivaRate?.toString() || ''}
          />

          <StudentTypeSummery
            type={'אברכים חצי יום'}
            sum={dashboard?.payKollelHalfDayCount?.toString() || ''}
            datotRate={dashboard?.kollelHalfDayRate?.toString() || ''}
          />

          <StudentTypeSummery
            type={'אברכים יום שלם'}
            sum={dashboard?.payKollelFullDayCount?.toString() || ''}
            datotRate={dashboard?.kollelFullDayRate?.toString() || ''}
          />
        </Stack>
      </Stack>
      {/* B */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{mx: 2, borderColor: 'black', borderStyle: 'dashed'}}
      />
      <Stack
        width="30%"
        spacing={2}
        sx={{
          height: '100%',
          alignItems: 'center',
          mb: 2
        }}
      >
        <Payment />
        <Stack
          sx={{
            textAlign: 'center',
            bottom: '1'
          }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <>
            <br />
            <Typography noWrap fontSize={14} fontWeight={500}>
              הצעה עבור חודש {month?.name ?? ''}
            </Typography>

            <Typography noWrap fontSize={14} fontWeight={500}>
              לתשלום בחודש {dashboard?.payMonth ?? ''}
            </Typography>
          </>
          <Stack
            spacing={2}
            width={'100%'}
            direction={'row'}
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize={32} fontWeight={700} sx={{pl: 1}}>
              {new Intl.NumberFormat('en-US').format(dashboard?.payAmount || 0)}
            </Typography>
            <Typography fontSize={12} fontWeight={700}>
              ש"ח
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
})

export default PaymentSummary

interface StudentTypeSummeryProps {
  type: string
  sum: string
  datotRate: string
}
const StudentTypeSummery = observer(
  ({type, sum, datotRate}: StudentTypeSummeryProps) => {
    return (
      <Stack
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{flex: 1, minWidth: 0}}
      >
        <Typography noWrap fontWeight={500}>
          {type}
        </Typography>
        <Typography fontWeight={500}>לתמיכה</Typography>

        <Typography fontSize={32} fontWeight={700}>
          {sum}
        </Typography>
        <Typography fontSize={12}>תעריף {datotRate}</Typography>
      </Stack>
    )
  }
)
