import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  TextField
} from '@mui/material'
import {DatePicker, TimeField} from '@mui/x-date-pickers'

import {observer} from 'mobx-react-lite'
import {useEffect, useState} from 'react'
import Autocomplete from 'src/components/ui/AutoComplete'
import {useMainStore} from 'src/contexts/Main'
import Audit from 'src/entities/Audit'
import CodeObject, {CodeObjectType} from 'src/entities/CodeObject'
import LabelCol from '../grid/columnTypes/LabelCol'

const AuditDetailsTab = observer(() => {
  const mainStore = useMainStore()
  const {currentInstitution, setCurrentAudit, getAudit, updateAudit} =
    mainStore.institutionStore
  const {codeObjects} = mainStore.codeTablesStore
  const [formData, setFormData] = useState<Audit>(new Audit())

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const resetError = () => {
    const newErrors: {[key: string]: string} = {}
    setErrors(newErrors)
  }

  const setErrorField = (key: string, value: any) => {
    if (!value || value === '')
      setErrors(prevErrors => ({
        ...prevErrors,
        [key]: 'שדה חובה'
      }))
    else {
      setErrors(prevErrors => ({
        ...prevErrors,
        [key]: ''
      }))
    }
  }

  const handleInputChange =
    (key: string, isRequired: boolean = false) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target
      setFormData(prevState => ({
        ...prevState,
        [key]: value
      }))
      if (isRequired) {
        setErrorField(key, value)
      }
    }

  const handleDateChange = (
    key: string,
    date: Date | undefined | null | string
  ) => {
    setFormData({...formData, [key]: date})
  }

  const handleAutCompleteChange = (key: string, value: any) => {
    setFormData({...formData, [key]: value})
    setErrorField(key, value)
  }

  const validateForm = () => {
    const newErrors: {[key: string]: string} = {}
    if (!formData.auditor) {
      newErrors.auditor = 'שדה חובה'
    }
    if (!formData.statusId) {
      newErrors.statusId = 'שדה חובה'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleEditClick = () => {
    resetError()

    setIsEditMode(true)
  }

  const handleSaveClick = async () => {
    if (!validateForm()) return
    setIsLoading(true)
    try {
      await updateAudit(formData)
      setCurrentAudit(formData)
      setIsEditMode(false)
    } catch (error) {
      console.error('Error saving audit:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelClick = () => {
    if (currentInstitution?.audit) {
      setFormData({...currentInstitution.audit})
    }
    resetError()
    setIsEditMode(false)
  }

  useEffect(() => {
    if (currentInstitution?.audit) {
      setFormData({...currentInstitution.audit})
    }
  }, [currentInstitution?.audit])

  useEffect(() => {
    let isMounted = true
    setIsEditMode(false)
    const fetchData = async () => {
      try {
        if (isMounted && currentInstitution) {
          getAudit(currentInstitution.id)
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getAudit, currentInstitution])

  return (
    <Paper sx={{width: '100%', p: 2}}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <TextField
            disabled={isEditMode === false}
            label="שם מבקר"
            name="auditor"
            value={formData.auditor}
            onChange={handleInputChange('auditor', true)}
            fullWidth
            required
            InputLabelProps={{
              shrink: true
            }}
            helperText={errors.auditor}
            error={isEditMode && !formData.auditor && !!errors.auditor}
            FormHelperTextProps={{sx: {color: 'red'}}}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <DatePicker
            format="dd/MM/yyyy"
            disabled={!isEditMode}
            openTo="day"
            views={['day', 'year', 'month']}
            label=" תאריך"
            slotProps={{
              textField: {
                InputProps: {},
                InputLabelProps: {
                  shrink: true
                }
              }
            }}
            value={formData.auditDate}
            onChange={newValue => handleDateChange('auditDate', newValue)}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <TimeField
            fullWidth
            disabled={!isEditMode}
            format="HH:mm:ss"
            label="שעה"
            value={formData.auditTime}
            onChange={newValue => handleDateChange('auditTime', newValue)}
            InputLabelProps={{
              shrink: true
            }}
            slotProps={{
              textField: {
                InputProps: {},
                InputLabelProps: {
                  shrink: true
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            disabled={!isEditMode}
            InputLabelProps={{
              shrink: true
            }}
            label="מגזר"
            name="sector"
            value={formData.sector}
            onChange={handleInputChange('sector')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            disabled={!isEditMode}
            label="מס’ בחורים"
            InputLabelProps={{
              shrink: true
            }}
            name="yeshivaGuyCount"
            value={formData.yeshivaGuyCount}
            onChange={handleInputChange('yeshivaGuyCount')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            disabled={!isEditMode}
            InputLabelProps={{
              shrink: true
            }}
            label=" מס’ אברכים - יום שלם"
            name="kollelManFullCount"
            value={formData.kollelManFullCount}
            onChange={handleInputChange('kollelManFullCount')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            disabled={!isEditMode}
            label=" מס’ אברכים - סדר א"
            InputLabelProps={{
              shrink: true
            }}
            name="kollelManShiftACount"
            value={formData.kollelManShiftACount}
            onChange={handleInputChange('kollelManShiftACount')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            disabled={!isEditMode}
            label=" מס’ אברכים - סדר ב  "
            InputLabelProps={{
              shrink: true
            }}
            name="kollelManShiftBCount"
            value={formData.kollelManShiftBCount}
            onChange={handleInputChange('kollelManShiftBCount')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            disabled={!isEditMode}
            label="מלגה "
            InputLabelProps={{
              shrink: true
            }}
            name="scholarship"
            value={formData.scholarship}
            onChange={handleInputChange('scholarship')}
            fullWidth
            multiline
            maxRows={4}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Autocomplete
            disabled={!isEditMode}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            size="large"
            renderOption={(props: any, option: any) => {
              return (
                <li {...props} key={option.id}>
                  <LabelCol
                    pointer
                    text={option.description || ''}
                    color={option.color || 'primary'}
                  />
                </li>
              )
            }}
            getOptionLabel={(option: any) => option.description}
            limitTags={1}
            renderTags={(value: any, getTagProps: any) =>
              value.map((option: CodeObject, index: number) => (
                <Chip
                  size="small"
                  color={option.color}
                  variant="soft"
                  label={option.description}
                  {...getTagProps({index})}
                  key={option.id}
                />
              ))
            }
            // Correctly handle the value
            value={
              codeObjects[CodeObjectType.AuditStatuses]?.find(
                b => b.id === formData.statusId
              ) || null
            }
            onChange={(newValue: CodeObject | null) => {
              handleAutCompleteChange('statusId', newValue?.id)
            }}
            isOptionEqualToValue={(option: CodeObject, v: CodeObject) =>
              option.id === v?.id
            }
            options={codeObjects[CodeObjectType.AuditStatuses] || []}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="סטטוס"
                required
                helperText={
                  isEditMode && !formData.statusId && !!errors.statusId
                    ? 'שדה חובה'
                    : ''
                }
                error={isEditMode && !formData.statusId && !!errors.statusId}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            disabled={!isEditMode}
            label="מקור קבלת הנתונים"
            name="sourceData"
            InputLabelProps={{
              shrink: true
            }}
            value={formData.sourceData}
            onChange={handleInputChange('sourceData')}
            fullWidth
            multiline
            maxRows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!isEditMode}
            label="הערות"
            InputLabelProps={{
              shrink: true
            }}
            name="note"
            value={formData.note}
            onChange={handleInputChange('note')}
            fullWidth
            multiline
            maxRows={7}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!isEditMode}
            label="רושם כללי "
            InputLabelProps={{
              shrink: true
            }}
            name="generalImpression"
            value={formData.generalImpression}
            onChange={handleInputChange('generalImpression')}
            fullWidth
            multiline
            maxRows={7}
          />
        </Grid>
        <Grid container spacing={4} justifyContent="flex-start">
          {isEditMode ? (
            <Grid item xs={12} sx={{p: 2, textAlign: 'left'}}>
              <Button
                variant="outlined"
                disabled={isLoading}
                sx={{m: 2}}
                onClick={handleCancelClick}
              >
                ביטול
              </Button>

              <Button
                variant="contained"
                sx={{m: 2}}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={20} />}
                onClick={handleSaveClick}
              >
                אישור
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} sx={{p: 2, textAlign: 'left', m: 2}}>
              <Button variant="contained" onClick={handleEditClick}>
                עריכה
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
})

export default AuditDetailsTab
