import {Box, Stack, Typography} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers'

import {observer} from 'mobx-react-lite'
import {useEffect} from 'react'
import {useMainStore} from 'src/contexts/Main'

const InstitutionalPaymentsSummery = observer(() => {
  const {selectedPaymetMonth, setSelectedPaymetMonth} =
    useMainStore().institutionStore

  const {getDashboardPaymentsByMonth, monthlyPaymentsSummary} =
    useMainStore().paymentsStore

  const currentMonthlyPaymentSummary = monthlyPaymentsSummary.find(
    m =>
      selectedPaymetMonth &&
      m.month === selectedPaymetMonth?.getMonth() + 1 &&
      m.year === selectedPaymetMonth?.getFullYear()
  )

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted) {
          getDashboardPaymentsByMonth()
          setSelectedPaymetMonth(new Date())
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [setSelectedPaymetMonth, getDashboardPaymentsByMonth])

  return (
    <>
      <Stack direction="row" gap={8} alignItems="center">
        <Box textAlign="center">
          <Typography variant="h6">
            {currentMonthlyPaymentSummary?.institutionsCount || 0}
          </Typography>
          <Typography variant="subtitle1" sx={{marginTop: 1}}>
            מוסדות
          </Typography>
        </Box>

        <Box textAlign="center">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            {currentMonthlyPaymentSummary?.kollelFullDayCount || 0}
          </Typography>
          <Typography variant="subtitle1" sx={{marginTop: 1}}>
            אברכים יום שלם
          </Typography>
        </Box>

        <Box textAlign="center">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            {currentMonthlyPaymentSummary?.kollelHalfDayCount || 0}
          </Typography>
          <Typography variant="subtitle1" sx={{marginTop: 1}}>
            אברכים חצי יום
          </Typography>
        </Box>

        <Box textAlign="center">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            {currentMonthlyPaymentSummary?.yeshivaCount?.toLocaleString() ||
              '0'}
          </Typography>
          <Typography variant="subtitle1" sx={{marginTop: 1}}>
            {' '}
            בחורים
          </Typography>
        </Box>

        <Box textAlign="center">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            {currentMonthlyPaymentSummary?.totalPaymentAmount?.toLocaleString() ||
              '0'}
          </Typography>
          <Typography variant="subtitle1" sx={{marginTop: 1}}>
            סכום ששולם
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        justifyContent="flex-end"
        alignItems="center"
      />
      <DatePicker
        format="MM/yyyy"
        openTo="month"
        views={['year', 'month']}
        label="בחירת חודש"
        value={selectedPaymetMonth}
        onChange={newValue => setSelectedPaymetMonth(newValue)}
        slotProps={{
          textField: {
            variant: 'standard',
            InputProps: {
              placeholder: 'בחר חודש'
            },
            InputLabelProps: {
              sx: {textAlign: 'right', width: '100%'}
            }
          }
        }}
      />
    </>
  )
})
export default InstitutionalPaymentsSummery
