import {makeAutoObservable} from 'mobx'

export class MonthlyPaymentSummary {
  year: number

  month: number

  totalPaymentAmount: number

  institutionsCount: number

  totalStudentsCount: number

  yeshivaCount: number

  kollelHalfDayCount: number

  kollelFullDayCount: number

  constructor(monthlyPaymentSummary: any) {
    this.year = monthlyPaymentSummary.Year
    this.month = monthlyPaymentSummary.Month
    this.totalPaymentAmount = monthlyPaymentSummary.TotalPaymentAmount
    this.institutionsCount = monthlyPaymentSummary.InstitutionsCount
    this.totalStudentsCount = monthlyPaymentSummary.TotalStudentsCount
    this.yeshivaCount = monthlyPaymentSummary.YeshivaCount
    this.kollelHalfDayCount = monthlyPaymentSummary.KollelHalfDayCount
    this.kollelFullDayCount = monthlyPaymentSummary.KollelFullDayCount

    makeAutoObservable(this)
  }
}
