import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config, {systemObjects} from 'src/config'
import {MainStore} from 'src/store/MainStore'
import Student, {StudentTab} from 'src/entities/Student'
import StudentReport from 'src/entities/StudentReport'
import Report from 'src/entities/Report'
import CodeObject from 'src/entities/CodeObject'
import {format} from 'date-fns'
import StudentRejection from 'src/entities/StudentRejection'
import Dashboard from '../entities/Dashboard'
import Month from 'src/entities/Month'
import {defaultMonth} from 'src/utils/month'

type DashboardMatrix = {
  [year: number]: {
    [month: number]: Dashboard
  }
}

export class StudentsStore {
  students: Student[] = []

  studentReports: StudentReport[] = []

  lastReport?: Report

  studentImportStatuses: CodeObject[] = []

  duplicates: Student[] = []

  rejects: StudentRejection[] = []

  studentTypes?: CodeObject[]

  studentStatuses?: CodeObject[]

  learningShifts?: CodeObject[]

  selectedInstitutionId?: number

  reportErrors: string[] = []

  isSaving: boolean = false

  dashboard?: Dashboard

  dashboardByMonth: DashboardMatrix | undefined = undefined

  rows: any[] = []

  selectedTab: StudentTab = StudentTab.All

  currentMonth: Month = defaultMonth

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.institutionStore.currentInstitution,
      currentInstitution => this.getData(currentInstitution?.id)
    )

    reaction(
      () => this.lastReport,
      lastReport => {
        if (lastReport) {
          this.getStudentReports()
          if (lastReport.statusId === 1)
            this.owner.institutionStore.importReport(lastReport.id)
        } else {
          this.studentReports = []
        }
      }
    )

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isAuth => {
        if (isAuth) {
          this.getStudentTypes()
          this.getStudentStatuses()

          this.getlearningShifts()
          this.getStudentImportStatuses()
          this.setCurrentMonth(this.currentMonth)
        }
      }
    )
  }

  setSelectedTab = (tab: StudentTab) => {
    this.selectedTab = tab
  }

  setCurrentMonth = (month: Month) => {
    this.currentMonth = month
    this.getMonthlyData(month)
  }

  getData = (instutionId?: number) => {
    if (instutionId) {
      this.reportErrors = []
      this.getStudents(this.currentMonth.id, this.currentMonth.year)
      this.getRejects(instutionId)
      this.getDuplicates(this.currentMonth.id, this.currentMonth.year)
      this.getLastReport(this.currentMonth.id, this.currentMonth.year)
      this.getStudentsDashboard(instutionId)
      this.getMonthDashboard(instutionId)
    } else {
      this.students = []
      this.lastReport = undefined
    }
  }

  getMonthlyData = (month: Month) => {
    this.getStudents(month.id, month.year)
    this.getDuplicates(month.id, month.year)
    this.getLastReport(month.id, month.year)
  }

  getStudentsData = () => {
    this.getData(this.owner.institutionStore.currentInstitution?.id)
  }

  getLastUpdate = () => {
    if (this.students?.length > 0) {
      return format(new Date(this.students[0].updatedAt), 'dd/MM/yyyy')
    }
    return ''
  }

  setSelectedInstitutionId = (institutionId: number) => {
    this.selectedInstitutionId = institutionId
  }

  getStudentTypes = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.studentTypes}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.studentTypes = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getStudentStatuses = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.studentStatuses}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.studentStatuses = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getlearningShifts = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.learningShifts}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.learningShifts = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getStudentImportStatuses = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.studentImportStatuses}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.studentImportStatuses = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getStudentReports = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/StudentReports/${this.lastReport?.id}/students`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: StudentReport[]) => {
        if (data) {
          runInAction(() => {
            this.studentReports = data.map(
              s =>
                new StudentReport(
                  s,
                  this.learningShifts!,
                  this.studentImportStatuses
                )
            )
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getLastReport = (month: number, year: number) => {
    const institutionId = this.owner.institutionStore.currentInstitution?.id

    if (!institutionId) {
      return
    }

    const params: any = {
      month,
      year
    }

    const url = new URL(
      `${config.apiUrl}/StudentReports/Institution/${institutionId}/last`
    )

    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )

    this.owner.loginStore
      .fetchWithUser(url.toString())
      .then((res: any) => {
        if (res.status === 204) {
          this.lastReport = undefined
          return
        }
        return res.json()
      })
      .then((data: Report) => {
        if (data) {
          runInAction(() => {
            this.lastReport = new Report(data)
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getStudents = (month: number, year: number) => {
    const institutionId = this.owner.institutionStore.currentInstitution?.id

    if (!institutionId) {
      return
    }

    const params: any = {
      month,
      year
    }

    const url = new URL(
      `${config.apiUrl}/Students/Institution/${institutionId}`
    )

    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )

    this.owner.loginStore
      .fetchWithUser(url.toString())
      .then((res: any) => {
        return res.json()
      })
      .then((data: Student[]) => {
        if (data) {
          runInAction(() => {
            const ss = data.map(s => new Student(s, this.learningShifts ?? []))
            this.students = ss
            if (this.selectedTab === StudentTab.All) {
              this.rows = ss
            } else if (this.selectedTab === StudentTab.Approved) {
              this.rows = ss.filter(s => s.isNeedPay)
            }
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getDuplicates = (month: number, year: number) => {
    const institutionId = this.owner.institutionStore.currentInstitution?.id

    if (!institutionId) {
      return
    }

    const params: any = {
      month,
      year
    }

    const url = new URL(
      `${config.apiUrl}/Students/Institution/${institutionId}/duplicates`
    )

    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )

    this.owner.loginStore
      .fetchWithUser(url.toString())
      .then((res: any) => {
        return res.json()
      })
      .then((data: Student[]) => {
        if (data) {
          runInAction(() => {
            const ss = data.map(s => new Student(s, this.learningShifts ?? []))
            this.duplicates = ss
            if (this.selectedTab === StudentTab.Duplicate) {
              this.rows = ss
            }
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getRejects = (institutionId?: number) => {
    if (!institutionId) this.students = []
    else {
      this.owner.loginStore
        .fetchWithUser(
          `${config.apiUrl}/Students/Institution/${institutionId}/rejections`
        )
        .then((res: any) => {
          return res.json()
        })
        .then((data: StudentRejection[]) => {
          if (data) {
            runInAction(() => {
              const ss = data.map(s => new StudentRejection(s))
              this.rejects = ss
              if (this.selectedTab === StudentTab.Rejected) {
                this.rows = ss
              }
            })
          }
        })
        .catch((e: any) => {
          console.log('error: ', e)
        })
    }
  }

  setFailedStatus = () => {
    this.lastReport?.setStatusId(3)
  }

  setIsSaving = (isSaving: boolean) => {
    this.isSaving = isSaving
  }

  approveReport = async () => {
    try {
      this.setIsSaving(true)
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StudentReports/${this.lastReport?.id}/accept`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      const errors = data as string[]
      if (errors.length === 0) {
        this.lastReport?.setStatusId(4)
        this.getStudents(this.currentMonth.id, this.currentMonth.year)
        this.getRejects(this.owner.institutionStore.currentInstitution?.id)
        this.getDuplicates(this.currentMonth.id, this.currentMonth.year)
      } else {
        this.lastReport?.setStatusId(5)
        this.reportErrors = errors
      }
      this.setIsSaving(false)
    } catch (error) {
      this.owner.setErrorMessage('התהליך נכשל, נסה שוב.')
    }
  }

  getStudentsDashboard = async (institutionId: number) => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Students/Institution/${institutionId}/dashboard`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.dashboard = new Dashboard(data)
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getMonthDashboard = async (institutionId: number) => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Students/Institution/${institutionId}/monthDashboard`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.dashboardByMonth = {}
            data.forEach((d: any) => {
              if (!this.dashboardByMonth![d.Year]) {
                this.dashboardByMonth![d.Year] = {}
              }
              this.dashboardByMonth![d.Year][d.Month] = new Dashboard(d)
            })
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  rejectStudent = async (student: Student, comment: string) => {
    student.setReject(!student.isReject)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Students/rejection`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            FirstName: student.firstName,
            LastName: student.lastName,
            IndificationNo: student.indificationNo,
            InstitutionId: student.institutionId,
            Comment: comment
          })
        }
      )
      const data = await response.json()
      if (data === 2) {
        this.rejects.push(new StudentRejection({}))
      }
      if (data === -1) {
        student.setReject(!student.isReject)
        this.owner.setErrorMessage('התלמיד כבר נדחה.')
      }
    } catch (error) {
      student.setReject(!student.isReject)
      this.owner.setErrorMessage('התהליך נכשל, נסה שוב.')
    }
  }

  cancelStudentRejection = async (studentRejectionId: number) => {
    const index = this.rejects.findIndex(item => item.id === studentRejectionId)
    let deleteObj: StudentRejection[] = []
    if (index !== -1) {
      deleteObj = this.rejects.splice(index, 1)
      this.rows = [...this.rejects]
    }
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Students/rejection/${studentRejectionId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (data === -1) throw new Error()
    } catch (error) {
      if (deleteObj.length) this.rejects.splice(index, 0, deleteObj[0])
      this.owner.setErrorMessage('התהליך נכשל, נסה שוב.')
    }
  }

  updateStudentStatus = async (
    student: Student,
    statusId: number | undefined
  ) => {
    const prevStatus = student.statusId
    if (prevStatus === statusId) return
    student.setStatus(statusId)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Students/${student.id}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(statusId)
        }
      )
      const data = await response.json()
      if (data) {
        const i = this.students.findIndex(s => s.id === student.id)
        if (i !== -1) {
          if (statusId === 1) {
            const help = [...this.students]
            help[i].isNeedPay = true
            this.students = help
          }
          if (prevStatus === 1) {
            const help = [...this.students]
            help[i].isNeedPay = false
            this.students = help
          }
          if (statusId === 2) {
            if (this.students.find(s => s.id === student.id))
              this.rejects.push(new StudentRejection({}))
          }
        }
      } else {
        student.setStatus(prevStatus)
        throw new Error()
      }
    } catch (error) {
      this.owner.setErrorMessage('התהליך נכשל, נסה שוב.')
    }
  }

  setRows = (rows: any[]) => {
    this.rows = rows
  }

  changeTab = async (tab: StudentTab) => {
    const institutionId = this.owner.institutionStore.currentInstitution?.id

    if (!institutionId) {
      return
    }

    const month = this.currentMonth.id
    const {year} = this.currentMonth
    this.rows = []
    this.selectedTab = tab
    switch (tab) {
      case StudentTab.All:
        this.getStudents(month!, year!)
        break
      case StudentTab.Approved:
        this.getStudents(month!, year!)
        break
      case StudentTab.Duplicate:
        this.getDuplicates(month!, year!)
        break
      case StudentTab.Rejected:
        this.getRejects(institutionId)
        break
      default:
        break
    }
  }
}
