import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config, {roles} from 'src/config'
import {MainStore} from 'src/store/MainStore'
import User from 'src/entities/User'
import ApprovePerson from 'src/entities/ApprovePerson'

export default class UsersStore {
  users?: User[]

  secretaries?: User[]

  approvePersons?: ApprovePerson[]

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isAuth => {
        if (isAuth) {
          this.getSecretaries()
          this.getApprovePersons()
          this.getUsers()
        }
      }
    )

    reaction(
      () => this.owner.loginStore.currentUser,
      user => {
        if (user) {
          this.getSecretaries()
        }
      }
    )
  }

  getSecretaries = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Users/byRoll/${roles.secretary}`)
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.secretaries = data.map((i: any) => new User(i))
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getApprovePersons = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Users/approvePersons`)
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.approvePersons = data.map((i: any) => new ApprovePerson(i))
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getUsers = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Users/all`)
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.users = data.map((u: any) => new User(u))
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }
}
